<template>
  <!-- data-html2canvas-ignore="true" -->
  <!-- Ignore / hide html -->

  <div
    v-if="dataReport && schoolDetail"
    class="reportCardDownloadContainer break-inside fullpage"
  >
    <div class="reportCardHeader">
      <div class="reportCardLogo">
        <img
          :src="schoolDetail.about.path_landscape"
          width="60px"
          height="60px"
        />
      </div>
      <div>
        <table class="no-border">
          <tbody>
            <tr>
              <td style="width: 150px">Nama Sekolah</td>
              <td style="width: 20px">:</td>
              <td>{{ schoolDetail.about.name }}</td>
              <td style="width: 150px">Kelas</td>
              <td style="width: 20px">:</td>
              <td>{{ dataReport.header.class_name }}</td>
            </tr>
            <tr>
              <td>Alamat</td>
              <td>:</td>
              <td>{{ schoolDetail.about.address }}</td>
              <td>Semester</td>
              <td>:</td>
              <td>{{ filter.semester }}</td>
            </tr>
            <tr>
              <td>Nama Siswa</td>
              <td>:</td>
              <td>{{ dataReport.header.student_name }}</td>
              <td>No. Induk</td>
              <td>:</td>
              <td>{{ dataReport.header.student_nis }}</td>
            </tr>
            <!-- <tr>
              <td>Mata Pelajaran</td>
              <td>:</td>
              <td>{{ dataReport.header }}</td>
              <td colspan="3"></td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>

    <div style="margin-bottom: 20px">
      <table>
        <thead>
          <tr>
            <th style="text-align: left" colspan="7">
              <h3>A. Nilai Sikap</h3>
            </th>
          </tr>
        </thead>
        <tbody v-if="dataReport.atitude && dataReport.atitude.length > 0">
          <tr v-for="(a, idx) in dataReport.atitude" :key="`${idx}-attitude`">
            <td colspan="2">
              <span style="padding-right: 2px">{{ idx + 1 }}</span
              >{{ a.name }}
            </td>
            <td>{{ a.score }}</td>
            <td>{{ a.description }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="pa-2">
              Data Nilai tidak ditemukan
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- v-if="dataReport.score && dataReport.score.length > 0" -->
    <!-- class="html2pdf__page-break" -->
    <div style="margin-bottom: 20px">
      <div class="mb-3">
        <table>
          <thead style="text-align: left">
            <tr>
              <th colspan="7">
                <h3>B. Pengetahuan dan Keterampilan</h3>
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(s, idx) in dataReport.score"
            :key="`${idx}-score`"
            style="text-align: left"
          >
            <tr>
              <td colspan="7">
                {{ s.lesson_group_name }} : <b>{{ s.lesson_group }}</b>
              </td>
            </tr>
            <tr>
              <td colspan="1" rowspan="2">
                Mata Pelajaran
              </td>
              <td colspan="3">
                Nilai Pengetahuan (K-3)
              </td>
              <td colspan="3">
                Nilai Pengetahuan (K-4)
              </td>
            </tr>
            <tr>
              <td>Angka</td>
              <td>Predikat</td>
              <td>Deskripsi</td>
              <td>Angka</td>
              <td>Predikat</td>
              <td>Deskripsi</td>
            </tr>
            <tr v-for="(m, idx) in s.member" :key="idx">
              <td>{{ m.lesson_master_name }}</td>
              <td>{{ m.ki_3 }}</td>
              <td>{{ m.ki_3_grade }}</td>
              <td>{{ m.ki_3_desc }}</td>
              <td>{{ m.ki_4 }}</td>
              <td>{{ m.ki_4_grade }}</td>
              <td>{{ m.ki_4_desc }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <table>
        <tbody>
          <tr>
            <td style="width: 150px">Total Nilai</td>
            <td style="width: 30px">:</td>
            <td><strong>60</strong></td>
          </tr>
          <tr>
            <td style="width: 150px">Rata - rata</td>
            <td style="width: 30px">:</td>
            <td><strong>60</strong></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="margin-bottom: 20px">
      <table>
        <thead>
          <tr>
            <th colspan="4" style="text-align: left">
              <h3>C. Extrakulikurer</h3>
            </th>
          </tr>
        </thead>
        <tbody
          v-if="
            dataReport.extracurricullar &&
              dataReport.extracurricullar.length > 0
          "
        >
          <tr>
            <td style="width: 50px">No.</td>
            <td>
              Kegiatan Exstrakurikuler
            </td>
            <td>
              Predikat
            </td>
            <td>Deskripsi</td>
          </tr>
          <tr
            v-for="(e, idx) in dataReport.extracurricullar"
            :key="`${idx}-attitude`"
          >
            <td>{{ idx + 1 }}</td>
            <td>{{ e.exschool_name }}</td>
            <td>{{ e.grade }}</td>
            <td>{{ e.description }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="pa-2">
              -
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="margin-bottom: 20px">
      <table>
        <thead>
          <tr>
            <th colspan="4" style="text-align: left">
              <h3>D. Prestasi</h3>
            </th>
          </tr>
        </thead>
        <tbody
          v-if="dataReport.achievement && dataReport.achievement.length > 0"
        >
          <tr>
            <td style="width: 50px">No.</td>
            <td>
              Jenis Prestasi
            </td>
            <td>Deskripsi</td>
          </tr>
          <tr
            v-for="(a, idx) in dataReport.achievement"
            :key="`${idx}-attitude`"
          >
            <td>{{ idx + 1 }}</td>
            <td>{{ a.description }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="pa-2">
              -
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="margin-bottom: 20px">
      <table>
        <thead>
          <tr>
            <th colspan="4">
              <h3>E. Ketidakhadiran</h3>
            </th>
          </tr>
        </thead>
        <tbody v-if="dataReport.absence">
          <tr>
            <td style="width: 150px">Sakit</td>
            <td style="width: 30px">:</td>
            <td>{{ dataReport.absence.sick }}</td>
          </tr>
          <tr>
            <td style="width: 150px">Ijin</td>
            <td style="width: 30px">:</td>
            <td>{{ dataReport.absence.leave }}</td>
          </tr>
          <tr>
            <td style="width: 150px">Tanpa Alasan</td>
            <td style="width: 30px">:</td>
            <td>{{ dataReport.absence.alpha }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="pa-2">
              -
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="margin-bottom: 20px">
      <table>
        <thead>
          <tr>
            <th style="text-align: left">
              <h3>F. Praktek Kerja Lapangan</h3>
            </th>
          </tr>
        </thead>
        <tbody v-if="dataReport.partical_work">
          <tr>
            <td>{{ dataReport.partical_work }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="pa-2">
              -
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="margin-bottom: 20px">
      <table>
        <thead>
          <tr>
            <th style="text-align: left">
              <h3>G. Catatan Wali Kelas</h3>
            </th>
          </tr>
        </thead>
        <tbody v-if="dataReport.summary">
          <tr>
            <td>{{ dataReport.summary }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="pa-2">
              -
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="reportCardFooter">
      <div style="text-align: center">
        <div style="margin-bottom: 5px">
          Mengetahui,<br />
          Orang Tua/Wali
        </div>

        <div style="margin-bottom: 65px">Guru Kelas</div>
        <div>( {{ dataReport.footer.guardian.name }} )</div>
      </div>
      <div style="text-align: center">
        <div style="margin-bottom: 70px">
          Guru Kelas
        </div>

        <div>
          {{ dataReport.footer.homeroom.name }}<br />
          NIP: {{ dataReport.footer.homeroom.nip }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataReport: Object,
    filter: Object
  },
  computed: {
    schoolDetail() {
      return this.$store.getters.g_school;
    }
  }
};
</script>

<style lang="scss">
.reportCardDownloadContainer {
  @mixin centerElement {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  font-size: 12px;
  table,
  th,
  td {
    border: 1px solid grey;
    .no-border {
      border: none;
    }
  }

  th {
    // background: lightgrey;
    padding: 7px;
  }
  td {
    padding: 7px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .reportCardHeader {
    margin-bottom: 20px;
    .reportCardLogo {
      font-size: 18px;
      font-weight: bold;
      @include centerElement;
    }
  }

  .reportCardFooter {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
</style>
