<template>
  <v-card class="pa-4" flat>
    <v-row no-gutters>
      <FilterSchoolYear
        :withInitial="true"
        :dataFilter="dataFilter"
        notMultiple
        @handler="getFilter"
      />
      <v-spacer></v-spacer>

      <v-tooltip v-if="dataTable.length > 0" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            depressed
            color="primary"
            :loading="loadingDownload"
            @click="downloadPdf()"
          >
            {{ $t("app.download") }} PDF
            <v-icon small class="ml-2">mdi-file-pdf-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("report_card.download_perclass") }}</span>
      </v-tooltip>
    </v-row>

    <v-divider class="my-3"></v-divider>

    <v-row no-gutters class="mb-3 mt-2" justify="end">
      <v-tooltip v-if="dataTable.length > 0" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            depressed
            color="primary"
            @click="setGenerate()"
            >{{ $t("report_card.get_score_data_class") }}
            <v-icon class="ml-2" small>mdi-arrow-down-bold-circle</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("report_card.getdata_perclass") }}</span>
      </v-tooltip>

      <v-tooltip v-if="dataTable.length > 0" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            depressed
            color="primary"
            class="ml-2"
            :class="{ 'mt-2': $vuetify.breakpoint.xsOnly }"
            @click="publishReport(null, 'published')"
            >{{ $t("report_card.publish") }}
            <v-icon class="ml-2" small>mdi-share</v-icon></v-btn
          >
        </template>
        <span>{{ $t("report_card.publish_perclass") }}</span>
      </v-tooltip>

      <v-tooltip v-if="dataTable.length > 0" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            depressed
            color="primary"
            class="ml-2"
            :class="{ 'mt-2': $vuetify.breakpoint.xsOnly }"
            @click="publishReport(null, 'unpublished')"
            >{{ $t("report_card.unpublish") }}
            <v-icon class="ml-2" small>mdi-close-circle-outline</v-icon></v-btn
          >
        </template>
        <span>{{ $t("report_card.unpublish_perclass") }}</span>
      </v-tooltip>
    </v-row>

    <v-card flat>
      <v-data-table
        v-model="selectedPublish"
        :headers="tableHeaders"
        :items="dataTable"
        :loading="loading"
        :page.sync="queryData.page"
        :items-per-page="queryData.limit"
        item-key="student"
        hide-default-footer
        disable-pagination
        class="elevation-0"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-alert
            v-if="item.status == 'DRAFT'"
            dense
            color="warning"
            class="ma-0"
            dark
          >
            {{ item.status | statusFormat }}</v-alert
          >
          <v-alert v-else-if="item.status == 'UNPROCCESS'" dense class="ma-0">
            {{ item.status | statusFormat }}</v-alert
          >
          <v-alert v-else dense color="success" class="ma-0" dark>
            {{ item.status | statusFormat }}</v-alert
          >
        </template>
        <template v-slot:[`item.generated_at`]="{ item }">
          {{ item.generated_at | dateFormat }}
        </template>
        <template v-slot:[`item.published_at`]="{ item }">
          {{ item.published_at | dateFormat }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip v-if="item.status != 'UNPROCCESS'" left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="item.status !== 'DRAFT'"
                fab
                depressed
                x-small
                color="primary"
                class="mr-2"
                @click="setGenerate(item)"
              >
                <v-icon small>mdi-arrow-down-bold-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("report_card.get_score_data") }}</span>
          </v-tooltip>
          <v-btn
            v-else
            color="primary"
            depressed
            small
            @click="setGenerate(item)"
          >
            {{ $t("report_card.get_score_data") }}
            <v-icon class="ml-2" small>mdi-arrow-down-bold-circle</v-icon>
          </v-btn>

          <span v-if="item.status != 'UNPROCCESS'">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  :disabled="item.status !== 'DRAFT'"
                  fab
                  depressed
                  x-small
                  color="primary"
                  class="mr-2"
                  @click="editReport(item)"
                >
                  <v-icon small>mdi-pencil-box-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("report_card.edit_report") }}</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  fab
                  depressed
                  x-small
                  color="primary"
                  class="mr-2"
                  :loading="loadingDownload"
                  @click="downloadPdf(item)"
                >
                  <v-icon small>mdi-file-pdf-box</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("app.download") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.status == 'DRAFT'" left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  fab
                  depressed
                  x-small
                  color="primary"
                  @click="publishReport(item, 'published')"
                >
                  <v-icon small>mdi-share</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("report_card.publish") }}</span>
            </v-tooltip>
            <v-tooltip v-else left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  fab
                  depressed
                  x-small
                  color="primary"
                  @click="publishReport(item, 'unpublished')"
                >
                  <v-icon small>mdi-close-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("report_card.unpublish") }}</span>
            </v-tooltip>
          </span>
        </template>
      </v-data-table>

      <Pagination
        :length="length"
        :total="total"
        :current-page="queryData.page"
        :limit="queryData.limit"
        :handler="paginateChange"
      />
    </v-card>

    <v-dialog v-model="modalSelectSubject" persistent max-width="500">
      <v-card>
        <v-card-title class="subtitle-1 font-weight-bold">
          Generate Nilai Sikap
          <span v-if="selectedStudent">
            : {{ selectedStudent && selectedStudent.name }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tabCompetencies">
            <v-tab>
              Nilai Spiritual
            </v-tab>
            <v-tab>
              Nilai Sosial
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabCompetencies" touchless>
            <v-tab-item>
              <v-data-table
                :headers="[
                  {
                    text: $t('app.subject'),
                    value: 'lesson'
                  },
                  {
                    text: $t('app.action'),
                    value: 'action'
                  }
                ]"
                :items="dataSubjectSpiritual"
                :loading="loadingSubject"
                class="mt-3"
              >
                <template v-slot:[`item.status`]="{ item }">
                  {{ item.status }}
                </template>
                <template v-slot:[`item.generated_at`]="{ item }">
                  {{ item.generated_at }}
                </template>
                <template v-slot:[`item.published_at`]="{ item }">
                  {{ item.published_at }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-radio-group v-model="selectedSubjectSpiritual">
                    <v-radio :value="item.id" hide-details />
                  </v-radio-group>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table
                :headers="[
                  {
                    text: $t('app.subject'),
                    value: 'lesson'
                  },
                  {
                    text: $t('app.action'),
                    value: 'action'
                  }
                ]"
                :items="dataSubjectSocial"
                :loading="loadingSubject"
                class="mt-3"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-radio-group v-model="selectedSubjectSocial">
                    <v-radio :value="item.id" hide-details />
                  </v-radio-group>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="
              () => {
                selectedSubjectSpiritual = null;
                selectedSubjectSocial = null;
                modalSelectSubject = false;
                selectedStudent = null;
              }
            "
          >
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="loadingSubject"
            :loading="loadingGenerate"
            depressed
            @click="generateReportCard()"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <vue-html2pdf
      ref="reportCard"
      :htmlToPdfOptions="pdfOptions()"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-content-width="97%"
      class="documentDownload"
      @progress="onProgress($event)"
    >
      <section slot="pdf-content">
        <ReportCardDownload :dataReport="dataReport" :filter="filterString" />
      </section>
    </vue-html2pdf>

    <ModalConfirm
      :title="
        this.confirmPublishType == 'unpublished'
          ? $t('report_card.unpublish_title')
          : $t('report_card.publish_title')
      "
      :close="() => (confirmPublish = false)"
      :loading="loadingPublish"
      :isOpen="confirmPublish"
      :save="publishReportCard"
    >
      {{ setConfirmText() }}
    </ModalConfirm>
  </v-card>
</template>

<script>
import FilterSchoolYear from "../../components/filterSchooYear";
import ReportCardDownload from "./ReportCardDownload";
import Pagination from "@/components/Pagination";
import i18n from "@/i18n";

import {
  getReportList,
  generateReport,
  getSubjectReport,
  downloadReport,
  downloadReportByClass,
  publishReportByClass,
  publishReportById
} from "@/api/admin/academic/reportCard";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment/moment";
moment.locale("id");

export default {
  components: {
    FilterSchoolYear,
    ReportCardDownload,
    Pagination,
    VueHtml2pdf,
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  data() {
    return {
      filter: {
        school_year: null,
        grade: null,
        class: null
      },
      filterString: {
        school_year: null,
        grade: null,
        class: null,
        semester: null
      },
      total: 0,
      length: 0,
      queryData: {
        page: 1,
        limit: 10
      },
      dataFilter: {},
      loadingDownload: false,
      tableHeaders: [
        {
          text: i18n.t("master_data.table.name"),
          value: "name"
        },
        {
          text: i18n.t("master_data.student.nomor"),
          value: "nis"
        },
        {
          text: i18n.t("master_data.table.phone"),
          value: "phone"
        },
        {
          text: i18n.t("report_card.status"),
          value: "status"
        },
        {
          text: i18n.t("report_card.generated_at"),
          value: "generated_at"
        },
        {
          text: i18n.t("report_card.published_at"),
          value: "published_at"
        },
        {
          text: i18n.t("app.action"),
          value: "action",
          width: 185
        }
      ],
      loading: false,
      loadingGenerate: false,
      dataTable: [],
      dataReport: null,
      selectedStudent: null,
      modalSelectSubject: false,
      selectedSubjectSpiritual: null,
      selectedSubjectSocial: null,
      dataSubjectSpiritual: [],
      dataSubjectSocial: [],
      loadingSubject: false,
      tabCompetencies: 0,
      selectedDownloadPdf: null,
      loadingPublish: false,
      confirmPublish: false,
      selectedPublish: [],
      confirmPublishType: "published"
    };
  },
  filters: {
    statusFormat: value => {
      if (!value) return "";
      let text = "";
      if (value == "DRAFT") text = i18n.t("report_card.unpublished_status");
      else if (value == "UNPROCCESS")
        text = i18n.t("report_card.unprocess_status");
      else text = i18n.t("report_card.published_status");
      return text;
    },
    dateFormat: value => {
      if (!value) return "";
      return moment(value).format("DD MMM YYYY HH:mm");
    }
  },
  mounted() {
    const q = this.$route.query;
    if (q.class) {
      this.dataFilter = { ...q };
      this.filter = {
        school_year: q.year,
        grade: q.grade,
        class: q.class
      };
      this.getData();
    }
  },
  methods: {
    getFilter(res) {
      switch (res.type) {
        case "school_year":
          this.filter.school_year = res.data.id;
          this.filterString.semester = res.data.semester;
          break;
        case "select_grade":
          this.filter.grade = res.data;
          break;
        case "select_class":
          this.filter.class = res.data;
          this.filterString.class = res.master.classList.find(
            m => m.id == res.data
          ).name;
          this.getData();
          break;
        default:
          break;
      }
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getData();
    },
    getData() {
      this.loading = true;
      getReportList({
        school_year: this.filter.school_year,
        classroom: this.filter.class,
        ...this.queryData
      })
        .then(res => {
          if (res.data.code) {
            this.dataTable = res.data.data.data;
            this.total = res.data.data.total;
            this.length = res.data.data.last_page;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    async getSubject() {
      this.loadingSubject = true;
      this.dataSubjectSpiritual = [];
      this.dataSubjectSocial = [];

      const filter = {
        school_year: this.filter.school_year,
        classroom: this.filter.class
      };

      const spiritual = await getSubjectReport({
        ...filter,
        competency: 1
      });

      const social = await getSubjectReport({
        ...filter,
        competency: 2
      });

      this.dataSubjectSpiritual = spiritual.data.data;
      this.dataSubjectSocial = social.data.data;
      this.loadingSubject = false;
    },
    setGenerate(row) {
      this.selectedStudent = row;
      this.modalSelectSubject = true;
      this.loadingSubject = true;
      this.getSubject();
    },
    generateReportCard() {
      this.loadingGenerate = true;
      let student = [];
      let generateType = {
        spiritual: this.selectedSubjectSpiritual || 0,
        social: this.selectedSubjectSocial || 0
      };

      if (this.selectedStudent) student = [this.selectedStudent.student];

      let data = {
        school_year: this.filter.school_year,
        classroom: this.filter.class,
        student: student,
        ...generateType
      };

      generateReport(data)
        .then(res => {
          if (res.data.code) {
            this.modalSelectSubject = false;
            this.selectedSubject = null;
            this.getData();
          }
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.message,
            color: res.data.code ? "success" : "error"
          });
          this.loadingGenerate = false;
        })
        .catch(() => (this.loadingGenerate = false));
    },
    editReport(item) {
      const f = this.filter;
      this.$router.push({
        name: "ReportCardDetail",
        params: {
          id: item.id,
          year: f.school_year,
          grade: f.grade,
          class: f.class
        }
      });
    },
    setConfirmText() {
      let text = "";
      if (this.selectedPublish.length > 0) {
        if (this.confirmPublishType == "unpublished")
          text = i18n.t("report_card.unpublish_text");
        else text = i18n.t("report_card.publish_text");
      } else {
        if (this.confirmPublishType == "unpublished")
          text = i18n.t("report_card.unpublish_text_mass");
        else text = i18n.t("report_card.publish_text_mass");
      }
      return text;
    },
    publishReport(item, type) {
      this.selectedPublish = [];
      if (item) this.selectedPublish = [item.id];
      this.confirmPublish = true;
      this.confirmPublishType = type;
    },
    publishReportCard() {
      if (this.selectedPublish.length == 0) {
        this.loadingPublish = true;
        publishReportByClass(
          {
            school_year: this.filter.school_year,
            classroom: this.filter.class
          },
          this.confirmPublishType
        )
          .then(res => {
            if (res.data.code) {
              this.getData();
              this.confirmPublish = false;
            }
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: res.data.code ? "success" : "error"
            });
            this.loadingPublish = false;
          })
          .catch(() => (this.loadingPublish = false));
      } else {
        this.loadingPublish = true;
        publishReportById(
          {
            id: this.selectedPublish
          },
          this.confirmPublishType
        )
          .then(res => {
            if (res.data.code) {
              this.getData();
              this.confirmPublish = false;
            }
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: res.data.code ? "success" : "error"
            });
            this.loadingPublish = false;
          })
          .catch(() => (this.loadingPublish = false));
      }
    },
    onProgress(e) {
      if (e == 100) this.$store.commit("TOGGLE_FULL_LOADING");
    },
    downloadUrl(dataurl) {
      const a = document.createElement("a");
      a.href = dataurl;
      // a.setAttribute("download", filename);
      a.click();
    },
    async downloadPdf(item) {
      this.$store.commit("TOGGLE_FULL_LOADING");
      if (item) {
        this.selectedDownloadPdf = item;
        downloadReport({ id: item.id })
          .then(res => {
            if (res.data.code) {
              window.open(res.data.data.path);
              this.selectedDownloadPdf = null;
              this.dataReport = null;
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.$store.commit("TOGGLE_FULL_LOADING");
          })
          .catch(() => this.$store.commit("TOGGLE_FULL_LOADING"));
      } else {
        downloadReportByClass({
          school_year: this.filter.school_year,
          classroom: this.filter.class
        })
          .then(async res => {
            if (res.data.code) {
              this.downloadUrl(res.data.data.path);
              this.selectedDownloadPdf = null;
              this.dataReport = null;
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.$store.commit("TOGGLE_FULL_LOADING");
          })
          .catch(() => this.$store.commit("TOGGLE_FULL_LOADING"));
      }
    },
    pdfOptions() {
      const filename = this.selectedDownloadPdf
        ? `${this.selectedDownloadPdf.name}-${this.selectedDownloadPdf.nis}.pdf`
        : "";

      return {
        margin: 0.3,
        filename: filename,
        image: {
          type: "jpeg",
          quality: 0.98
        },
        enableLinks: false,
        html2canvas: {
          scale: 1,
          bottom: 20,
          useCORS: true
        },
        jsPDF: {
          unit: "cm",
          format: "a4",
          orientation: "portrait"
        }
      };
    }
  }
};
</script>
